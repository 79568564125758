/*
 * @Description: 权限检测判断注入app
 * @Author: 琢磨先生
 * @Date: 2022-12-16 10:50:01
 * @LastEditors: cts 308360781@qq.com
 * @LastEditTime: 2024-12-17 21:25:25
 */

const install = (app) => {
  /**
   * 通过api地址（url）判断是否拥有请求的权限
   */
  app.config.globalProperties.$power = (url) => {
    var apis = [];
    var json = sessionStorage.getItem(
      app.config.globalProperties.$cfg.API_STORE_KEY
    );
    if (json) {
      apis = JSON.parse(json);
    }
    return apis.findIndex((o) => o.toLowerCase() == url.toLowerCase()) > -1;
  };
};

export default { install };

<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2023-02-18 21:33:47
 * @LastEditors: cts 308360781@qq.com
 * @LastEditTime: 2024-12-17 20:46:03
-->
<template>
  <el-scrollbar ref="scrollbar">
    <el-card class="box query">
      <el-form :inline="true">
        <el-form-item label="客户">
          <el-input
            v-model="query.khq"
            placeholder="姓名、手机号"
            clearable
            @input="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="报备人/带看人">
          <el-input
            v-model="query.bbq"
            placeholder="姓名、手机号"
            clearable
            @input="onSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="公司">
          <el-select
            v-model="query.isDeyou"
            placeholder="请选择"
            clearable
            style="width: 100px"
            @change="onSelectChange"
          >
            <el-option label="德佑" :value="true"></el-option>
            <el-option label="分销" :value="false"></el-option>
          </el-select>
          <!-- <el-button type="primary" @click="onSearch" icon="search" round
              >查询</el-button
            > -->
        </el-form-item>
        <el-form-item label="分号">
          <el-select
            v-model="query.number"
            clearable
            style="width: 100px"
            placeholder="请选择"
            @change="onSelectChange"
          >
            <el-option
              :label="i"
              :value="i"
              v-for="i in fbNum"
              :key="i"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label=""
          ><b style="color: var(--el-color-primary)"
            >{{ countdown > 9 ? countdown : `0${countdown}` }}s</b
          >后刷新
          <el-switch v-model="stopRefresh" @change="onRefreshChange"></el-switch
          ><span>暂停刷新</span>
        </el-form-item>
        <el-form-item>
          <el-button
            type="default"
            size="small"
            @click="onRefresh"
            icon="refresh"
            round
            >立即刷新</el-button
          >
        </el-form-item>
        <el-form-item label="">
          <add-report @success="loadData"></add-report>
        </el-form-item>
      </el-form>
    </el-card>
    <div class="report_wrp">
      <div class="left">
        <!-- <el-card class="box data">
        <el-form>
          <el-form-item label>
            <el-input
              v-model="q"
              placeholder="关键字搜索楼盘项目"
              clearable
              @input="keyChange"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="estate_box">
          <el-scrollbar>
            <el-checkbox-group v-model="query.estateIds" @change="estateChange">
              <div class="item" v-for="item in estate_list" :key="item.id">
                <el-checkbox :label="item.id">
                  {{ item.name }}
                  <span class="alias" v-if="item.tagAlias"
                    >({{ item.tagAlias }})</span
                  >
                </el-checkbox>
              </div>
            </el-checkbox-group>
          </el-scrollbar>
        </div>
      </el-card> -->
        <estate-box @change="onEstateChange"></estate-box>
      </div>
      <div class="right">
        <el-card class="box data report_data" v-loading="loading">
          <div style="margin-bottom: 10px; font-size: 20px">
            共查询到
            <b style="font-weight: blob">{{ data_list.length }}</b>
            条报备记录
          </div>
          <div v-if="!loading && data_list.length">
            <div class="grid">
              <div class="item" v-for="(item, index) in data_list" :key="index">
                <el-card shadow="hover">
                  <el-descriptions :column="1">
                    <el-descriptions-item label="报备次数">{{
                      item.manyTimes
                    }}</el-descriptions-item>
                    <el-descriptions-item label="系统编号">{{
                      item.id
                    }}</el-descriptions-item>
                    <el-descriptions-item label="报备单号">{{
                      item.hNumber
                    }}</el-descriptions-item>
                    <el-descriptions-item label="公司名称">
                      <span>{{ item.company }}</span>
                      <!-- <el-tooltip content="复制" placement="top">
                        <el-button
                          type="default"
                          plain
                          size="small"
                          icon="CopyDocument"
                          style="margin-left: 10px"
                          @click="onCopyText(item.company)"
                        ></el-button>
                      </el-tooltip> -->
                    </el-descriptions-item>
                    <el-descriptions-item label="带看人"
                      ><span>{{ item.daikanName }}</span
                      >/<span>{{ item.daikanMobile }}</span>
                      <!-- <el-tooltip content="复制姓名" placement="top">
                        <el-button
                          type="default"
                          plain
                          size="small"
                          icon="CopyDocument"
                          style="margin-left: 10px"
                          @click="onCopyText(item.daikanName)"
                        ></el-button>
                      </el-tooltip>
                      <el-tooltip content="复制手机号" placement="top">
                        <el-button
                          type="default"
                          plain
                          size="small"
                          icon="CopyDocument"
                          style="margin-left: 10px"
                          @click="onCopyText(item.daikanMobile)"
                        ></el-button>
                      </el-tooltip> -->
                    </el-descriptions-item>
                    <el-descriptions-item label="报备人"
                      >{{ item.consumerName }}/{{
                        item.consumerMobile
                      }}</el-descriptions-item
                    >
                    <el-descriptions-item label="客户姓名"
                      ><span>{{ item.clientName }}</span>
                      <el-tooltip content="复制" placement="top">
                        <el-button
                          type="default"
                          plain
                          size="small"
                          icon="CopyDocument"
                          style="margin-left: 10px"
                          @click="onCopyText(item.clientName)"
                        ></el-button>
                      </el-tooltip>
                    </el-descriptions-item>
                    <el-descriptions-item label="客户性别">{{
                      item.sex
                    }}</el-descriptions-item>
                    <el-descriptions-item label="客户号码"
                      ><span>{{ item.clientMobile }}</span>
                      <el-tooltip content="复制" placement="top">
                        <el-button
                          type="default"
                          plain
                          size="small"
                          icon="CopyDocument"
                          style="margin-left: 10px"
                          @click="onCopyText(item.clientMobileConverted)"
                        ></el-button>
                      </el-tooltip>
                    </el-descriptions-item>
                    <el-descriptions-item label="带看项目"
                      >{{ item.estateName }}
                      {{
                        item.estate.tagAlias ? `(${item.estate.tagAlias})` : ""
                      }}
                    </el-descriptions-item>
                    <el-descriptions-item label="到案人数">{{
                      item.dueNum
                    }}</el-descriptions-item>
                    <el-descriptions-item label="到案时间">{{
                      item.dueTime
                    }}</el-descriptions-item>
                    <el-descriptions-item label="报备备注">{{
                      item.comment
                    }}</el-descriptions-item>
                    <el-descriptions-item label="报备时间">{{
                      item.creationTime
                    }}</el-descriptions-item>
                    <!-- <el-descriptions-item label="状态">
                    <el-link type="primary" :underline="false">{{
                      item.cur_status_text
                    }}</el-link>
                  </el-descriptions-item> -->
                    <el-descriptions-item label="处理人"
                      >{{ item.modify_name }}
                      {{ item.modifiedTime }}</el-descriptions-item
                    >
                    <!-- <el-descriptions-item> </el-descriptions-item> -->
                  </el-descriptions>
                  <div class="buttons">
                    <el-button
                      type="success"
                      size="small"
                      icon="CopyDocument"
                      plain
                      v-if="item.isCanLock"
                      @click="onLock(item)"
                      :loading="item.loading"
                      >复制并锁定</el-button
                    >

                    <!-- <el-popconfirm
                    title="您确定是否将状态设定为已处理？"
                    width="220"
                    v-if="item.isCanSetSend"
                    @confirm="onSent(item)"
                  >
                    <template #reference>
                      <el-button
                        type="primary"
                        size="small"
                        icon="Setting"
                        plain
                        :loading="item.loading"
                        >设置已报送</el-button
                      >
                    </template>
                  </el-popconfirm> -->
                    <el-button
                      type="primary"
                      size="small"
                      icon="Setting"
                      plain
                      @click="onSent(item)"
                      v-if="item.isCanSetSend"
                      :loading="item.loading"
                      >设置已报送</el-button
                    >
                    <el-tooltip
                      v-if="item.isCanSetSend"
                      content="复制"
                      placement="top"
                    >
                      <el-button
                        type="success"
                        plain
                        size="small"
                        icon="CopyDocument"
                        :loading="item.loading"
                        @click="onCopy(item)"
                      ></el-button>
                    </el-tooltip>
                  </div>
                </el-card>
                <div :class="`number num_${item.number}`">
                  {{ item.number }}
                </div>
                <div class="test" v-if="item.isTest">测试</div>
              </div>
            </div>
          </div>
          <el-empty
            description="暂无数据"
            v-if="!loading && !data_list.length"
          />
        </el-card>
      </div>
    </div>
  </el-scrollbar>
</template>

<script>
// import useClipboard from "vue-clipboard3";
import EstateBox from "../estate_box.vue";
import AddReport from "./add.vue";
// const { toClipboard } = useClipboard();

export default {
  components: {
    EstateBox,
    AddReport,
  },
  data() {
    return {
      loading: true, //首次加载
      estate_list: [],
      query: {},
      q: "", //项目关键字
      attr_list: [],
      data_list: [],
      fbNum: 1, //分号数量
      countdown: 15,
      stopRefresh: false, //停止刷新
    };
  },
  created() {
    var userJson = localStorage.getItem("user");
    if (userJson) {
      var user = JSON.parse(userJson);
      if (user.fenBus && user.fenBus.length) {
        var numArr = user.fenBus.map((x) => {
          if (x.mktgNum > x.userNum) return x.mktgNum;
          return x.userNum;
        });
        this.fbNum = Math.max.apply(null, numArr);
      }
    }

    this.loadEstate();
    this.loadData();

    // this.t = setInterval(() => {
    //   this.countdown--;
    //   if (this.countdown <= 0) {
    //     this.countdown = 15;
    //     this.loadData();
    //   }
    // }, 1000);
    this.startRefresh();
    // this.copy();
  },
  unmounted() {
    clearInterval(this.t);
  },
  methods: {
    /**
     * 暂停刷新按钮更改
     */
    onRefreshChange() {
      if (this.stopRefresh) {
        clearInterval(this.t);
      } else {
        this.startRefresh();
      }
    },
    /**
     * 开启刷新
     */
    startRefresh() {
      this.t = setInterval(() => {
        this.countdown--;
        if (this.countdown <= 0) {
          this.countdown = 15;
          this.loadData();
        }
      }, 1000);
    },

    /**
     * 立即刷新
     */
    onRefresh() {
      this.countdown = 15;
      this.loadData();
    },

    /**
     * 分销、分号更改
     */
    onSelectChange() {
      this.onSearch();
    },

    /**
     * 加载楼盘列表
     */
    loadEstate() {
      this.$http.get("admin/v1/reportNew/estate").then((res) => {
        if (res.code == 0) {
          this.estates = res.data;
          this.estate_list = this.estates;
        }
      });
    },

    // /**
    //  * 关键字搜索楼盘
    //  * @param {*} val
    //  */
    // keyChange(val) {
    //   this.query.estateIds = [];
    //   this.estate_list = this.estates.filter((x) => x.name.indexOf(val) > -1);
    //   if (!val) {
    //     delete this.query.estateIds;
    //     this.onSearch();
    //   }
    // },

    // /**
    //  * 楼盘选择更改
    //  */
    // estateChange() {
    //   this.onSearch();
    // },

    /**
     * 楼盘选择更改
     * @param {*} list
     */
    onEstateChange(list) {
      this.query.estateIds = list;
      this.onSearch();
    },

    /**
     * 查询
     */
    onSearch() {
      this.loadData();
    },

    /**
     * 加载数据
     */
    loadData() {
      // this.loading = true;
      this.$http
        .post("admin/v1/reportNew", this.query)
        .then((res) => {
          if (res.code == 0) {
            this.data_list = res.data;
          }
        })
        .finally(() => (this.loading = false));
    },

    /**
     * 复制
     */
    onCopy(item) {
      // if (navigator.clipboard) {
      //   navigator.clipboard.writeText(item.copyTxt).then(() => {
      //     this.$message.success("已复制");
      //   });
      // } else {
      //   this.execCopyTxt(item);
      // }
      this.execCopyTxt(item).then(() => {
        this.$message.success("已复制");
      });
    },

    /**
     * 复制并锁定
     * @param {*} item
     */
    onLock(item) {
      item.loading = true;
      this.execCopyTxt(item)
        .then(() => {
          this.$http
            .get("admin/v1/reportNew/lock?id=" + item.id)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success(res.msg);
                this.loadData();
              } else {
                this.$message.error(res.msg);
              }
            })
            .finally(() => (item.loading = false));
        })
        .catch(() => {
          item.loading = false;
        });

      // toClipboard(item.copyTxt)
      //   .then(() => {
      //     this.$http
      //       .get("admin/v1/reportNew/lock?id=" + item.id)
      //       .then((res) => {
      //         if (res.code == 0) {
      //           this.$message.success(res.msg);
      //           this.loadData();
      //         } else {
      //           this.$message.error(res.msg);
      //         }
      //       })
      //       .finally(() => (item.loading = false));
      //   })
      //   .catch(() => {
      //     this.$message.error("复制失败，请重试");
      //   })
      //   .finally(() => {
      //     item.loading = false;
      //   });
    },

    /**
     * execCommand 复制
     */
    execCopyTxt(item) {
      // if (!this.isAndroid() &&
      //   navigator.clipboard &&
      //   typeof navigator.clipboard.writeText === "function"
      // ) {
      //   return navigator.clipboard.writeText(item.copyTxt);
      // } else {
      //   return new Promise((resolve, reject) => {
      //     try {
      //       var dummy = document.createElement("textarea");
      //       document.body.appendChild(dummy);
      //       dummy.value = item.copyTxt;
      //       dummy.select();
      //       document.execCommand("copy");
      //       document.body.removeChild(dummy);
      //       resolve();
      //     } catch (e) {
      //       reject(e);
      //     }
      //   });
      // }
      return new Promise((resolve, reject) => {
          try {
            var dummy = document.createElement("textarea");
            document.body.appendChild(dummy);
            dummy.value = item.copyTxt;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);
            resolve();
          } catch (e) {
            reject(e);
          }
        });
    },

    /**
     * 复制文本
     * @param text
     */
    onCopyText(text) {
      // if (!this.isAndroid() &&
      //   navigator.clipboard &&
      //   typeof navigator.clipboard.writeText === "function"
      // ) {
      //   navigator.clipboard
      //     .writeText(text)
      //     .then(() => {
      //       this.$message.success("已复制：" + text);
      //     })
      //     .catch((e) => {
      //       this.$message.error("复制失败，请重试。" + e);
      //     });
      // } else {
      //   var dummy = document.createElement("textarea");
      //   document.body.appendChild(dummy);
      //   dummy.value = text;
      //   dummy.select();
      //   document.execCommand("copy");
      //   document.body.removeChild(dummy);
      //   this.$message.success("已复制：" + text);
      // }
      var dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        this.$message.success("已复制：" + text);
    },

    isAndroid() { 
      return /android/ig.test(navigator.userAgent);
    },

    /**
     * 设置已经发开发商
     */
    onSent(item) {
      item.loading = true;
      this.$http
        .get("admin/v1/reportNew/sent?id=" + item.id)
        .then((res) => {
          if (res.code == 0) {
            this.loadData();
          }
        })
        .finally(() => (item.loading = false));
    },
  },
};
</script>

<style scoped>
.report_wrp .grid .item {
  position: relative;
}
.report_wrp .grid .item .number {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1;
  background: var(--el-color-primary);
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 0 4px;
  text-align: center;
  line-height: 30px;
}
.report_wrp .grid .item .number.num_2 {
  background: var(--el-color-success);
}

.report_wrp .grid .item .test {
  position: absolute;
  height: 60px;
  width: 60px;
  border-radius: 30px;
  border: 2px dotted var(--el-color-warning);
  color: var(--el-color-warning);
  line-height: 60px;
  text-align: center;
  top: 0;
  left: 0;
}

/* 已经锁定的数据 */
.report_wrp .locked_box {
  height: 300px;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 2;
  bottom: 0;
  background: white;
  right: 0;
  width: 100%;
  border-top: 1px solid var(--el-border-color);
  box-shadow: var(--el-box-shadow-lighter);
}
</style>

<!--
 * @Description: 编辑楼盘
 * @Author: 琢磨先生
 * @Date: 2023-12-28 08:56:24
 * @LastEditors: cts 308360781@qq.com
 * @LastEditTime: 2024-12-18 15:11:15
-->
<template>
  <el-drawer
    :title="title"
    v-model="visible"
    size="1000"
    :destroy-on-close="true"
    :show-close="true"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    @closed="onClosed"
  >
    <el-scrollbar v-loading="loading">
      <div v-if="importItem">
        <el-descriptions :column="1">
          <el-descriptions-item label="楼盘图标">
            <el-image
              style="width: 120px; height: 90px"
              :src="importItem.fosLogo"
              fit="contain"
            />
          </el-descriptions-item>
          <el-descriptions-item label="楼盘名称">
            {{ importItem.title }}
          </el-descriptions-item>
          <el-descriptions-item label="均价">
            {{ importItem.average }}{{ importItem.priceUnit }}
          </el-descriptions-item>
          <el-descriptions-item label="起价">
            {{ importItem.startingPrice }}{{ importItem.startPriceUnitValue }}
          </el-descriptions-item>
          <el-descriptions-item label="省市县">
            {{ importItem.areaName }}
          </el-descriptions-item>
          <el-descriptions-item label="地址">
            {{ importItem.address }}
          </el-descriptions-item>
        </el-descriptions>
        <el-divider></el-divider>
      </div>

      <el-form
        :model="form"
        ref="form"
        :rules="rules"
        label-width="140px"
        :inline="false"
      >
        <el-form-item label="代理类型" prop="estateType">
          <el-radio-group v-model="form.estateType">
            <el-radio
              :label="parseInt(item.value)"
              v-for="item in enums.estate_types"
              :key="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="报备分部" prop="attributeId">
          <el-radio-group v-model="form.attributeId">
            <el-radio
              :label="item.id"
              v-for="item in branch_list"
              :key="item.id"
              >{{ item.name }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="区域" prop="udfAreaId">
          <el-select v-model="form.udfAreaId" placeholder="">
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in udfAreaList"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目名称" prop="name">
          <el-col :span="14">
            <el-input v-model="form.name" placeholder=""></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="别名" prop="tagAlias">
          <el-col :span="12">
            <el-input v-model="form.tagAlias" placeholder=""></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="状态" prop="stopStatus">
          <el-radio-group v-model="form.stopStatus">
            <el-radio
              :label="parseInt(item.value)"
              v-for="item in enums.estate_status"
              :key="item.value"
              >{{ item.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="全号报备">
          <el-checkbox :label="true" v-model="form.isFullNumber"
            >是</el-checkbox
          >
          <div class="help-block">
            勾选 报备时必需录入全号，否则可隐号 如：138*1234
          </div>
        </el-form-item>
        <el-divider>复制</el-divider>
        <el-form-item label="隐藏手机号">
          <el-checkbox :label="true" v-model="form.isHiddenMobile"
            >是</el-checkbox
          >
        </el-form-item>
        <el-form-item label="隐藏中间位数" prop="hiddenNum">
          <el-col :span="4">
            <el-input v-model="form.hiddenNum" placeholder=""></el-input>
          </el-col>
          <div class="help-block">非全号报备时必填，不能大于7位</div>
        </el-form-item>

        <el-form-item label="复制自定义公司">
          <el-checkbox :label="true" v-model="form.isHiddenCompany"
            >是</el-checkbox
          >
        </el-form-item>
        <el-form-item label="自定义公司名称" prop="copyCompanyName">
          <el-col :span="12">
            <el-input v-model="form.copyCompanyName" placeholder=""></el-input>
          </el-col>
          <div class="help-block">
            自定义公司时，如果未填写则复制“三亚德佑房地产投资服务有限公司”全称。
          </div>
        </el-form-item>
        <el-form-item label="二级分销前缀" prop="companyPrefix">
          <el-col :span="10">
            <el-input v-model="form.companyPrefix" placeholder=""></el-input>
          </el-col>
          <div class="help-block">
            未自定义公司时，二级分销名字自带前缀。不填则不自带。
          </div>
        </el-form-item>

        <el-form-item label="复制性别">
          <el-checkbox :label="true" v-model="form.copySex">是</el-checkbox>
        </el-form-item>

        <el-divider>保护期、说明</el-divider>

        <el-form-item label="提前报备">
          <el-col :span="5">
            <el-input v-model="form.aheadHour" placeholder="">
              <template #suffix>小时</template>
            </el-input>
          </el-col>
          <el-col :span="5">
            <el-input v-model="form.aheadMin" placeholder="">
              <template #suffix>分</template>
            </el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="报备保护期">
          <el-col :span="5">
            <el-checkbox :label="true" v-model="form.protectTheDay"
              >当天有效</el-checkbox
            ></el-col
          >
          <el-col :span="5">
            <el-input v-model="form.protectDays" placeholder="">
              <template #suffix>天</template>
            </el-input>
          </el-col>
          <el-col :span="5">
            <el-input v-model="form.protectHour" placeholder="">
              <template #suffix>小时</template>
            </el-input>
          </el-col>
          <div class="help-block">勾选“当天有效”优先使用，将覆盖时间选择。</div>
        </el-form-item>

        <el-form-item label="客户保护天数">
          <el-col :span="4">
            <el-input v-model="form.dkSecureDays" placeholder="">
              <template #suffix>天</template>
            </el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="允许几人报备">
          <el-col :span="4">
            <el-input v-model="form.limitNum" placeholder="">
              <template #suffix>人</template>
            </el-input>
          </el-col>
          <div class="help-block">
            保护期内允许几人报备相同客户到此项目。0：则不限制（所有人可报）
          </div>
        </el-form-item>

        <el-form-item label="分销政策" prop="fxPolicy">
          <el-col :span="16">
            <el-input
              v-model="form.fxPolicy"
              type="textarea"
              placeholder=""
              :rows="6"
            >
            </el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="报备说明">
          <el-col :span="16">
            <el-input
              v-model="form.tuijianDesc"
              type="textarea"
              placeholder=""
              :rows="6"
            >
            </el-input>
          </el-col>
        </el-form-item>

        <el-divider>带看、确认</el-divider>
        <el-form-item label="项目有带看单？">
          <el-checkbox :label="true" v-model="form.isUpDKTicket"
            >是</el-checkbox
          >
        </el-form-item>

        <el-form-item label="需要到案确认">
          <el-checkbox :label="true" v-model="form.isYBConfirm">是</el-checkbox>
        </el-form-item>

        <el-form-item label="有二维码？">
          <el-checkbox :label="true" v-model="form.isGetQRCode">是</el-checkbox>
        </el-form-item>

        <el-form-item label="需手动请求二维码">
          <el-checkbox :label="true" v-model="form.needReqQRCode"
            >是</el-checkbox
          >
        </el-form-item>
        <el-form-item label="请求二维码时间间隔">
          <el-col :span="4">
            <el-input v-model="form.qrAfterMinutes" placeholder="">
              <template #suffix>分钟</template>
            </el-input>
          </el-col>
          <div class="help-block">重复申请时间间隔，分钟为单位，0：不限</div>
        </el-form-item>

        <el-form-item label="请求二维码超时停止">
          <el-col :span="4">
            <el-input v-model="form.qrBeforeHouse" placeholder="">
              <template #suffix>小时</template>
            </el-input>
          </el-col>
          <div class="help-block">
            报备几小时候后拒绝获取,小时为单位，0：不限
          </div>
        </el-form-item>

        <el-form-item label="二次报备是否等待">
          <el-checkbox :label="true" v-model="form.erCiGetQRAwait"
            >是</el-checkbox
          >
        </el-form-item>

        <el-divider>其它</el-divider>

        <el-form-item label="详情下架">
          <el-checkbox :label="true" v-model="form.isSoldOut">是</el-checkbox>
        </el-form-item>
        <el-form-item label="需购房资格">
          <el-checkbox :label="true" v-model="form.isQualification"
            >是</el-checkbox
          >
          <div class="help-block">需要购房资格的项目</div>
        </el-form-item>
        <el-form-item label="隐藏带看人手机">
          <el-checkbox :label="true" v-model="form.isHideDaikanMobile4"
            >是</el-checkbox
          >
          <div class="help-block">隐藏带看人手机号中间4位</div>
        </el-form-item>
        <el-form-item label="负责人接收短信通知">
          <el-checkbox :label="true" v-model="form.isFzrReceiveSms"
            >是</el-checkbox
          >
          <div class="help-block">勾选则标识报备将发送短信通知负责人</div>
        </el-form-item>
      </el-form>
    </el-scrollbar>
    <template #footer>
      <el-button @click="visible = false" :disabled="saving">取消</el-button>
      <el-button type="primary" :loading="saving" @click="onSubmit"
        >确定</el-button
      >
    </template>
  </el-drawer>
</template>

<script>
import common_api from "@/http/common_api";
import branch_api from "@/http/branch_api";
import udfarea_api from "@/http/udfarea_api";

export default {
  data() {
    return {
      visible: false,
      saving: false,
      loading: false,
      title: "",
      form: {},
      rules: {
        estateType: [{ required: true, message: "请选择", trigger: "blur" }],
        attributeId: [{ required: true, message: "请选择", trigger: "blur" }],
        udfAreaId: [{ required: true, message: "请选择", trigger: "blur" }],
        stopStatus: [{ required: true, message: "请选择", trigger: "blur" }],
        fxPolicy: [{ required: true, message: "请输入", trigger: "blur" }],
        name: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      enums: {},
      branch_list: [], //分部
      udfAreaList: [], //自定义区域
    };
  },
  props: {
    id: Number,
    importItem: Object,
  },
  emits: ["closed", "success"],
  watch: {
    id: {
      handler() {
        if (this.id) {
          this.loadDtl();
          this.visible = true;
          // this.title = "修改";
        }
      },
      immediate: true,
    },
    importItem: {
      handler() {
        if (this.importItem) {
          this.visible = true;
          this.title = "导入楼盘";
          this.form = {
            FwwEstateId: this.importItem.id,
            estateType: 0,
            name: this.importItem.title,
            stopStatus: 0,
            aheadHour: 0,
            aheadMin: 0,
            protectDays: 0,
            protectHour: 0,
            dkSecureDays: 0,
            limitNum: 0,
            qrAfterMinutes: 0,
            qrBeforeHouse: 0,
            // attributeId:this.branch_list[0].id,
          };
        }
      },
      immediate: true,
    },
  },
  created() {
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.enums = res.data;
      }
    });

    branch_api.get_select().then((res) => {
      if (res.code == 0) {
        this.branch_list = res.data;
      }
    });
    udfarea_api.get_select().then((res) => {
      if (res.code == 0) {
        this.udfAreaList = res.data;
      }
    });
  },

  methods: {
    /**
     *
     */
    onClosed() {
      this.$emit("closed");
    },
    /**
     *
     */
    loadDtl() {
      this.loading = true;
      this.$http
        .get("admin/v1/estate/dtl?id=" + this.id)
        .then((res) => {
          if (res.code == 0) {
            this.form = Object.assign({}, res.data);
            this.title = `修改【${res.data.fwwName}(房产网名称)】`;
          }
        })
        .finally(() => (this.loading = false));
    },

    /**
     * 提交
     */
    onSubmit() {
      if (
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.saving = true;
            this.$http
              .post("admin/v1/estate/edit", this.form)
              .then((res) => {
                if (res.code == 0) {
                  this.$emit("success");
                  this.visible = false;
                }
              })
              .finally(() => (this.saving = false));
          }
        })
      );
    },
  },
};
</script>

<style scoped></style>

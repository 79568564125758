<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2023-02-28 22:18:22
 * @LastEditors: cts 308360781@qq.com
 * @LastEditTime: 2024-12-15 09:38:20
-->
<template>
  <el-card class="box query">
    <el-form :model="query" ref="query" :inline="true">
      <el-form-item label="关键字">
        <el-input
          v-model="query.q"
          placeholder="姓名、手机号"
          style="width: 300px"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search"
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <div>
      <add-user v-if="is_can_edit" @success="loadData"></add-user>
      <!-- <el-button type="primary" size="small" icon="plus" v-if="is_can_edit" round>新增</el-button> -->
    </div>
  </el-card>
  <el-card class="box data" v-loading="loading">
    <el-table :data="tableData.data" border stripe >
      <el-table-column
        label="姓名"
        prop="name"
        width="150"
        fixed="left"
      ></el-table-column>
      <el-table-column
        label="账号"
        prop="account"
        width="150"
      ></el-table-column>
      <el-table-column
        label="手机"
        prop="mobilePhone"
        width="150"
      ></el-table-column>
      <el-table-column label="性别" prop="sex" width="100"></el-table-column>
      <el-table-column label="状态" prop="enabled" width="120">
        <template #default="scope">
          <el-tag type="success" v-if="scope.row.enabled">正常</el-tag>
          <el-tag type="danger" v-else>禁用</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="角色" min-width="300">
        <template #default="scope">
          {{ scope.row.roles.map((o) => o.name).join("、") }}
        </template>
      </el-table-column>
      <el-table-column label="分部" min-width="200">
        <template #default="scope">
          {{ scope.row.fenBus.map((o) => o.name).join("、") }}
        </template>
      </el-table-column>

      <el-table-column
        label="创建时间"
        prop="creationTime"
        width="180"
      ></el-table-column>
      <el-table-column label="操作" width="180" fixed="right">
        <template #default="scope">
          <el-button
            type="primary"
            link
            size="small"
            @click="onEdit(scope.row)"
            icon="edit"
            v-if="is_can_edit"
            >修改</el-button
          >
          <el-popconfirm
            width="220"
            v-if="is_can_del"
            :title="`您确定要重置【${scope.row.name}】登录密码？`"
            @confirm="onResetPwd(scope.row)"
          >
            <template #reference>
              <el-button
                size="small"
                link
                icon="refresh"
                :loading="scope.row.resetting"
                >重置密码</el-button
              >
            </template>
          </el-popconfirm>

          
          <el-popconfirm
            width="220"
            v-if="!scope.row.isAdmin && is_can_del"
            title="您确定要删除此用户？"
            @confirm="onDelete(scope.row)"
          >
            <template #reference>
              <el-button
                size="small"
                type="danger"
                link
                icon="delete"
                :loading="scope.row.deleting"
                >删除</el-button
              >
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
    ></el-pagination>
  </el-card>
  <edit-user
    :item="current_item"
    @success="onSuccess"
    @closed="current_item = null"
  ></edit-user>
</template>

<script>
import AddUser from "./add_user.vue";
import EditUser from "./edit_user.vue";
export default {
  components: {
    AddUser,
    EditUser,
  },
  data() {
    return {
      loading: false,
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      avatarUrl: require("@/assets/avatar.png"),
      tableData: {
        counts: 0,
      },
      current_item: null,
      is_can_edit: false,
      is_can_del: false,
    };
  },
  created() {
    this.is_can_edit = this.$power("admin/v1/user/edit");
    this.is_can_del = this.$power("admin/v1/user/del");
    this.loadData();
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.current = null;
      this.loading = true;
      this.$http
        .post("/admin/v1/user", this.query)
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data;
          }
        })
        .finally(() => (this.loading = false));
    },
    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadData();
      }
    },

    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },

    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },

    /**
     * 修改
     */
    onEdit(item) {
      this.current_item = item;
    },

    /**
     *
     */
    onSuccess() {
      this.current_item = null;
      this.loadData();
    },

    /**
     * 删除
     */
    onDelete(item) {
      item.deleting = true;
      this.$http.get(`admin/v1/user/del?id=${item.id}`).then((res) => {
        if (res.code == 0) {
          if (this.query.pageIndex > 1) {
            if (this.tableData.data.length <= 1) {
              this.query.pageIndex--;
            }
          }
          this.loadData();
        }
      });
    },

    /**
     * 
     * @param item 
     */
    onResetPwd(item){
      item.resetting = true;
      this.$http.get(`admin/v1/user/resetpwd?id=${item.id}`).then((res) => {
        if (res.code == 0) {
          this.loadData();
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped></style>

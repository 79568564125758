<!--
 * @Description: 我的已处理
 * @Author: 琢磨先生
 * @Date: 2023-02-18 21:33:47
 * @LastEditors: cts 308360781@qq.com
 * @LastEditTime: 2024-12-15 09:34:06
-->
<template>
    <el-scrollbar ref="scrollbar">
  <el-card class="box query">
    <el-form
      :model="query"
      ref="query"
      :inline="true" 
    >
      <el-form-item label="单号">
        <el-input
          v-model="query.no"
          placeholder="报备单号"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="客户">
        <el-input
          v-model="query.q"
          placeholder="姓名、手机号"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="报备人">
        <el-input
          v-model="query.consumer"
          placeholder="报备人姓名、手机号"
          style="width: 220px"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="带看人">
        <el-input
          v-model="query.daikan"
          placeholder="带看人姓名、手机号"
          style="width: 220px"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="报备日期">
        <el-date-picker
          v-model="query.dates"
          type="daterange"
          value-format="YYYY-MM-DD"
          unlink-panels
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="状态">
        <el-select
          v-model="query.status"
          placeholder
          clearable
          style="width: 150px"
        >
          <el-option
            :label="item.label"
            :value="item.value"
            v-for="item in enums.report_status"
            :key="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="公司分类">
        <el-select
          v-model="query.is_deyou"
          placeholder
          clearable
          style="width: 150px"
        >
          <el-option label="德佑" :value="true"></el-option>
          <el-option label="分销" :value="false"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button
          type="primary"
          round
          @click="onSearch"
          icon="search" 
          >查询</el-button
        >
      </el-form-item>
    </el-form>
    <div></div>
  </el-card>

  <div class="report_wrp">
    <div class="left">
      <!-- <el-card class="box data">
        <el-form>
          <el-form-item label>
            <el-input
              v-model="q"
              placeholder="关键字搜索楼盘项目"
              clearable
              @input="keyChange"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="estate_box">
          <el-scrollbar>
            <el-checkbox-group v-model="query.estateIds" @change="estateChange">
              <div class="item" v-for="item in estate_list" :key="item.id">
                <el-checkbox :label="item.id">
                  {{ item.name }}
                  <span class="alias" v-if="item.tagAlias"
                    >({{ item.tagAlias }})</span
                  >
                </el-checkbox>
              </div>
            </el-checkbox-group>
          </el-scrollbar>
        </div>
      </el-card> -->
      <estate-box @change="onEstateChange"></estate-box>
    </div>
    <div class="right">
      <el-card class="box data report_data" v-loading="loading">
        <div v-if="tableData.counts">
          <div style="margin-bottom: 10px; font-size: 20px">
            总共 <b>{{ tableData.counts }}</b> 条已处理记录
          </div>
          <div class="grid">
            <div
              class="item"
              v-for="(item, index) in tableData.data"
              :key="index"
            >
              <el-card shadow="hover">
                <!-- <el-descriptions :column="1">
                <el-descriptions-item label="系统编号">{{
                  item.id
                }}</el-descriptions-item>
                <el-descriptions-item label="报备单号">{{
                  item.hNumber
                }}</el-descriptions-item>
                <el-descriptions-item label="公司名称">{{
                  item.company
                }}</el-descriptions-item>
                <el-descriptions-item label="带看项目">{{
                  item.estateName
                }}</el-descriptions-item>
                <el-descriptions-item label="带看人"
                  >{{ item.daikanName }}/{{
                    item.daikanMobile
                  }}</el-descriptions-item
                >
                <el-descriptions-item label="报备人"
                  >{{ item.consumer_Name }}/{{
                    item.consumer_Mobile
                  }}</el-descriptions-item
                >
                <el-descriptions-item label="客户姓名">{{
                  item.clientName
                }}</el-descriptions-item>
                <el-descriptions-item label="客户性别">{{
                  item.sex
                }}</el-descriptions-item>
                <el-descriptions-item label="客户手机">{{
                  item.clientMobile
                }}</el-descriptions-item>
                <el-descriptions-item label="到案人数">{{
                  item.dueNum
                }}</el-descriptions-item>
                <el-descriptions-item label="到案时间">{{
                  item.dueTime
                }}</el-descriptions-item>
                <el-descriptions-item label="报备备注">{{
                  item.comment
                }}</el-descriptions-item>
                <el-descriptions-item label="报备时间">{{
                  item.creationTime
                }}</el-descriptions-item>
                <el-descriptions-item label="状态">
                  <el-link
                    type="warning"
                    :underline="false"
                    v-if="item.curStatus == 0"
                    >{{ item.cur_status_text }}</el-link
                  >
                  <el-link
                    type="primary"
                    :underline="false"
                    v-else-if="item.curStatus == 1"
                    >{{ item.cur_status_text }}</el-link
                  >
                  <el-link
                    type="success"
                    :underline="false"
                    v-else-if="item.curStatus == 2"
                    >{{ item.cur_status_text }}</el-link
                  >
                  <el-link
                    type="danger"
                    :underline="false"
                    v-else-if="item.curStatus == 3"
                    >{{ item.cur_status_text }}</el-link
                  >
                  <el-link :underline="false" v-else>{{
                    item.cur_status_text
                  }}</el-link>
                  <el-tag
                    type="danger"
                    size="small"
                    effect="dark"
                    v-if="item.unknown"
                    >结果未知</el-tag
                  >
                </el-descriptions-item>
                <el-descriptions-item label="处理时间"
                  >{{ item.modify_name }} {{ item.modifiedTime }}

                  <span v-if="item.isAcData"
                    >{{ item.kefu_name }}({{ item.kefu_mobile }})</span
                  >
                </el-descriptions-item>
                <el-descriptions-item label="处理结果">{{
                  item.resultContent
                }}</el-descriptions-item>
                <el-descriptions-item label="二维码">
                  <span v-if="item.estate.isGetQRCode">
                    <el-image
                      :src="item.qrCodeUrl"
                      fit="cover"
                      :initial-index="1"
                      :preview-src-list="[item.qrCodeUrl]"
                      style="width: 50px; height: 50px; margin-right: 10px"
                    ></el-image>
                    <el-button
                      type="info"
                      size="small"
                      icon="upload"
                      v-if="item.estate.isGetQRCode"
                      @click="onUploadQrCode(item)"
                      >上传二维码</el-button
                    >
                  </span>
                  <span v-else style="display: inline-block; height: 55px"
                    >项目无二维码</span
                  >
                </el-descriptions-item>
                <el-descriptions-item label="附件">
                  <el-button
                    v-if="item.attUrls && item.attUrls.length"
                    type="primary"
                    link
                    icon="view"
                    @click="onPreviewAtt(item)"
                    >查看附件({{ item.attUrls.length }})</el-button
                  >
                </el-descriptions-item>
                <el-descriptions-item>
                  <el-button
                    type="warning"
                    size="small"
                    icon="upload"
                    @click="onUploadAtt(item)"
                    >上传附件</el-button
                  >
                </el-descriptions-item>
              </el-descriptions> -->
                <report-item
                  :item="item"
                  @preview-att="onPreviewAtt"
                  @upload-qr-code="onUploadQrCode"
                ></report-item>
                <div class="buttons">
                  <el-button
                    type="info"
                    color="#626aef"
                    size="small"
                    icon="upload"
                    @click="onUploadAtt(item)"
                    >上传附件</el-button
                  >
                </div>
              </el-card>
            </div>
          </div>
        </div>
        <el-empty v-if="!loading && !tableData.counts"></el-empty>
        <el-pagination
          v-if="tableData.counts"
          @size-change="pageSizeChange"
          @current-change="pageCurrentChange"
          :current-page="query.pageIndex"
          :page-sizes="[20, 40, 60, 80, 100]"
          :page-size="query.pageSize"
          layout="total, sizes, prev, pager, next"
          :total="tableData.counts"
        ></el-pagination>
      </el-card>
    </div>
  </div> 
</el-scrollbar>

  <upload-qr-code
    :item="current_qr_item"
    @closed="current_qr_item = null"
    @success="loadData"
  ></upload-qr-code>
  <upload-att
    :item="current_att_item"
    @closed="current_att_item = null"
    @success="loadData"
  ></upload-att>

  <el-dialog title="查看附件(点击可预览)" v-model="visibleDialog">
    <div
      class="att_image_preview"
      v-if="preview_att_item && preview_att_item.attUrls"
    >
      <el-image
        style="width: 100px; height: 100px"
        :src="url"
        v-for="(url, index) in preview_att_item.attUrls"
        :key="index"
        :preview-src-list="preview_att_item.attUrls"
        :initial-index="index"
        fit="cover"
      />
    </div>
  </el-dialog>
</template>

<script>
import UploadQrCode from "../newqrcode/upload_qrcode.vue";
import ReportItem from "@/views/report/report_item";
import common_api from "@/http/common_api";
import UploadAtt from "../att/upload_att.vue";
import EstateBox from "../estate_box.vue";

export default {
  components: {
    UploadQrCode,
    UploadAtt,
    ReportItem,
    EstateBox,
  },
  data() {
    return {
      loading: false,
      visibleDialog: false,
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      enums: {},
      tableData: {
        counts: 0,
      },
      q: "", //项目关键字
      estate_list: [],
      current_qr_item: null,
      current_att_item: null,
      preview_att_item: null,
    };
  },
  created() {
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.enums = res.data;
      }
    });
    this.loadEstate();

    this.loadData();
  },
  methods: {
    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
      this.$refs.scrollbar.setScrollTop(0); 

    },

    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
      this.$refs.scrollbar.setScrollTop(0); 

    },

    /**
     * 加载楼盘列表
     */
    loadEstate() {
      this.$http.get("admin/v1/reportNew/estate").then((res) => {
        if (res.code == 0) {
          this.estates = res.data;
          this.estate_list = this.estates;
        }
      });
    },

    // /**
    //  * 关键字搜索楼盘
    //  * @param {*} val
    //  */
    // keyChange(val) {
    //   this.query.estateIds = [];
    //   this.estate_list = this.estates.filter((x) => x.name.indexOf(val) > -1);
    // },
    // /**
    //  * 楼盘选择更改
    //  */
    // estateChange() {
    //   this.onSearch();
    // },

    /**
     * 楼盘选择更改
     * @param {*} list
     */
    onEstateChange(list) {
      this.query.estateIds = list;
      this.onSearch();
    },

    /**
     *
     */
    loadData() {
      this.loading = true;
      this.$http
        .post("/admin/v1/report/handled", this.query)
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data; 
          }
        })
        .finally(() => (this.loading = false));
    },

    /**
     * 上传二维码
     * @param {*} item
     */
    onUploadQrCode(item) {
      this.current_qr_item = item;
    },

    /**
     * 上传附件
     * @param {*} item
     */
    onUploadAtt(item) {
      this.current_att_item = item;
    },

    /**
     * 查看附件
     */
    onPreviewAtt(item) {
      this.preview_att_item = item;
      this.visibleDialog = true;
    },
  },
};
</script>

<style scoped></style>

<template>
  <el-card class="box query">
    <el-form :model="query" ref="query" :inline="true">
      <el-form-item label="楼盘">
        <el-input
          v-model="query.q"
          placeholder="楼盘名称"
          clearable
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search"
          >查询</el-button
        >
      </el-form-item>
    </el-form>
  </el-card>
  <el-card class="box data" v-loading="loading">
    <el-table :data="tableData.data" border stripe>
      <el-table-column label="ID" prop="intId" width="100"></el-table-column>
      <el-table-column label="Logo图">
        <template #default="scope">
          <el-image
            style="width: 120px; height: 90px"
            :src="scope.row.fosLogo"
            fit="contain"
          />
        </template>
      </el-table-column>
      <el-table-column label="楼盘" prop="title" width="300"></el-table-column>
      <el-table-column label="城市/详细地址" min-width="200">
        <template #default="scope">
          <div>{{ scope.row.areaName }}</div>
          <div>{{ scope.row.address }}</div>
        </template>
      </el-table-column>
      <el-table-column label="关联的本地楼盘" width="200">
        <template #default="scope">
          {{ scope.row.bdEstates.map((x) => x.name).join("、") }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template #default="scope">
          <el-button
            type="primary"
            link
            size="small"
            @click="onImport(scope.row)"
            >导入本地</el-button
          >
          <!-- <el-button type="primary" link size="small">关联本地楼盘</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
    ></el-pagination>
  </el-card>
  <edit-loupan :import-item="importItem"></edit-loupan>
</template>

<script>
import EditLoupan from "../loupan/edit_loupan.vue";

export default {
  components: {
    EditLoupan,
  },
  data() {
    return {
      loading: false,
      query: {
        pageIndex: 1,
        pageSize: 20,
        sort: {},
        q: "",
      },
      tableData: {
        counts: 0,
      },
      importItem: null,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.$http
        .post("admin/v1/house", this.query)
        .then((res) => {
          if (res.code == 0) {
            this.tableData = res.data;
          }
        })
        .finally(() => (this.loading = false));
    },
    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.sort = {
          order: e.order,
          prop: e.prop,
        };
        this.loadData();
      }
    },

    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },

    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },

    /**
     * 导入本地
     * @param {*} item
     */
    onImport(item) {
      this.importItem = item;
    },
  },
};
</script>

<style scoped></style>

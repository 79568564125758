<!--
 * @Description: 项目报备周月统计
 * @Author: 琢磨先生
 * @Date: 2023-02-18 21:33:47
 * @LastEditors: cts 308360781@qq.com
 * @LastEditTime: 2024-12-15 09:36:54
-->
<template>
    <el-card class="box query">
      <el-form :model="query" ref="query" :inline="true">
        <el-form-item label="项目名称">
          <el-input v-model="query.q" placeholder="" clearable style="width: 300px;"></el-input>
        </el-form-item>
        <el-form-item label="日期">
          <el-date-picker
            v-model="query.date"
            value-format="YYYY-MM-DD"
            :clearable="false"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" round @click="onSearch" icon="search"
            >查询</el-button
          >
  
          <el-tooltip
            class="box-item"
            effect="dark"
            content="下载查询结果数据"
            placement="top"
          >
            <el-button
              icon="Download"
              v-power="'admin/v1/reportAnalysis/estate/week/export_excel'"
              @click="downloadExcel"
              :loading="downloading"
              >下载数据</el-button
            >
          </el-tooltip>
        </el-form-item>
      </el-form>
      <div></div>
    </el-card>
    <el-card class="box data" v-loading="loading">
      <el-table :data="tableData.data" border stripe>
        <el-table-column
          label="项目"
          prop="estateName"
          width="300"
        ></el-table-column>
        <el-table-column
          :label="`当日(${query.date})`"
          prop="dayCounts"
        ></el-table-column>
        <el-table-column label="当周" prop="weekCounts"></el-table-column>
        <el-table-column label="当月" prop="monthCounts"></el-table-column>
      </el-table>
      <el-pagination
        @size-change="pageSizeChange"
        @current-change="pageCurrentChange"
        :current-page="query.pageIndex"
        :page-sizes="[20, 40, 60, 80, 100]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="tableData.counts"
      ></el-pagination>
    </el-card>
  </template>
  
  <script>
  import fileDownload from "js-file-download";
  export default {
    data() {
      return {
        loading: false,
        downloading: false,
        query: {
          pageIndex: 1,
          pageSize: 20,
          sort: {
            order: "",
            prop: "",
          },
          q: "",
        },
        tableData: {
          counts: 0,
        },
      };
    },
    created() {
      this.query.date = this.$moment().format("YYYY-MM-DD");
  
      this.loadData();
    },
    methods: {
      /**
       * 搜索
       */
      onSearch() {
        this.query.pageIndex = 1;
        this.loadData();
      },
      /**
       * 分页页数更改
       */
      pageSizeChange(val) {
        this.query.pageSize = val;
        this.loadData();
      },
  
      /**
       * 分页页码更改
       */
      pageCurrentChange(val) {
        this.query.pageIndex = val;
        this.loadData();
      },
  
      /**
       *
       */
      loadData() {
        this.loading = true;
        this.$http
          .post("/admin/v1/reportAnalysis/estate/week", this.query)
          .then((res) => {
            if (res.code == 0) {
              this.tableData = res.data;
            }
          })
          .finally(() => (this.loading = false));
      },
      /**
       * 下载数据
       */
      downloadExcel() {
        this.downloading = true;
        var arg = Object.assign({}, this.query);
        delete arg.storeIds;
        if (
          Array.isArray(this.query.storeIds) &&
          this.query.storeIds.length > 0
        ) {
          arg.store_ids = this.query.storeIds.map((x) => {
            return x[1];
          });
        }
        this.$http
          .post("admin/v1/reportAnalysis/estate/week/export_excel", arg, {
            responseType: "blob",
          })
          .then((res) => {
            fileDownload(res, "分销周月统计.xlsx");
          })
          .finally(() => {
            this.downloading = false;
          });
      },
    },
  };
  </script>
  
  <style scoped></style>
  
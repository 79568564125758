<template>
  <el-button type="primary" size="small" @click="onAddOpen" icon="plus" round
    >添加报备</el-button
  >

  <el-drawer
    title="添加报备"
    v-model="drawerVisible"
    size="800px"
    append-to-body
    :destroy-on-close="true"
    :close-on-click-modal="false"
  >
    <el-form :model="form" ref="formRef" :rules="rules" label-width="140px">
      <el-form-item label="公司" prop="companyId">
        <!-- <el-select
          v-model="form.companyId"
          placeholder="请选择公司"
          clearable
          filterable
          :remote-method="getCompanyList"
          remote
        >
          <el-option
            v-for="item in companyList"
            :label="item.companyName"
            :value="item.id"
            :key="item.id"
          ></el-option>
        </el-select> -->
        <el-col :span="20">
          <choose-company
            :item="selection_company"
            @change="chooseCompanyChange"
          ></choose-company>
        </el-col>
        <!-- <el-input v-model="form.companyId"></el-input> -->
      </el-form-item>
      <el-form-item label="客户姓名" prop="clientName">
        <el-col :span="10">
          <el-input v-model="form.clientName"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="客户号码" prop="clientMobile">
        <el-col :span="10">
          <el-input v-model="form.clientMobile"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="客户性别" prop="sex">
        <el-radio-group v-model="form.sex">
          <el-radio label="男" value="男"></el-radio>
          <el-radio label="女" value="女"></el-radio>
        </el-radio-group>
        <!-- <el-input v-model="form.sex"></el-input> -->
      </el-form-item>

      <el-form-item label="带看项目" prop="estateIds">
        <el-col :span="20">
          <choose-estate
            :item="selectEstate"
            multiple
            @change="onEstateChange"
          ></choose-estate
        ></el-col>
        <el-text v-if="selectEstate">{{ selectEstate.tuijianDesc }}</el-text>
      </el-form-item>
      <el-form-item label="到案人数" prop="dueNum">
        <el-col :span="10">
          <el-input v-model="form.dueNum"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="到案时间" prop="dueTime">
        <el-date-picker v-model="form.dueTime" type="datetime"></el-date-picker>
      </el-form-item>
      <el-form-item label="带看人" prop="daikanName">
        <el-col :span="10">
          <el-input v-model="form.daikanName"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="带看人手机" prop="daikanMobile">
        <el-col :span="10">
          <el-input v-model="form.daikanMobile"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item label="报备人" prop="">
        <el-col :span="10">
          <el-select
            v-model="form.consumerId"
            placeholder="手机号搜索/选择报备人"
            :remote-method="onGetConsumer"
            clearable
            filterable
            remote
            :loading="loading"
            :disabled="!form.companyId"
            remote-show-suffix
          >
            <el-option
              v-for="item in consumerList"
              :label="`${item.name}${item.mobile}`"
              :value="item.id"
              :key="item.id"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="1"></el-col>
        <el-col :span="10">
          <el-input
            v-model="form.consumerMobile"
            placeholder="报备人手机号"
          ></el-input>
        </el-col>
        <div class="help-block">
          注意：找不到报备人时，请输入报备人的手机号码
        </div>
      </el-form-item>
      <el-form-item label="备注" prop="comment">
        <el-col :span="20">
          <el-input v-model="form.comment" type="textarea"></el-input>
        </el-col>
      </el-form-item>

      <el-form-item label="测试？">
        <el-col :span="20">
          <el-checkbox label="是" v-model="form.isTest"></el-checkbox>
        </el-col>
      </el-form-item>

      <el-form-item label="">
        <div style="margin-left: 140px">
          <el-button type="primary" @click="onSubmit" :loading="saving"
            >确定</el-button
          >
          <el-button type="default" @click="onDiscern">粘贴识别</el-button>
        </div>
      </el-form-item>
    </el-form>
  </el-drawer>

  <el-dialog
    title="粘贴识别"
    v-model="dialogVisible"
    width="600px"
    append-to-body
  >
    <el-form
      :model="disForm"
      ref="disFormRef"
      :rules="rules"
      label-width="80px"
    >
      <el-form-item label="">
        <el-input
          v-model="disForm.input"
          type="textarea"
          placeholder="请粘贴报备信息"
          :rows="14"
        ></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button type="primary" @click="onDiscernSubmit">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import ChooseCompany from "@/views/component/choose_company.vue";
import ChooseEstate from "@/views/component/choose_estate.vue";

export default {
  components: {
    ChooseCompany,
    ChooseEstate,
  },
  data() {
    return {
      drawerVisible: false,
      dialogVisible: false,
      form: {
        dueNum: "1",
      },
      saving: false,
      loading: false, //报备人搜索加载
      rules: {
        companyId: [{ required: true, message: "请选择公司", trigger: "blur" }],
        clientName: [{ required: true, message: "必填", trigger: "blur" }],
        clientMobile: [{ required: true, message: "必填", trigger: "blur" }],
        sex: [{ required: true, message: "必填", trigger: "blur" }],
        estateIds: [{ required: true, message: "必填", trigger: "blur" }],
        dueNum: [{ required: true, message: "必填", trigger: "blur" }],
        dueTime: [{ required: true, message: "必填", trigger: "blur" }],
        daikanName: [{ required: true, message: "必填", trigger: "blur" }],
        daikanMobile: [{ required: true, message: "必填", trigger: "blur" }],
      },
      selection_company: null,
      selectEstate: null, //选中的楼盘
      consumerList: [],
      disForm: {},
    };
  },
  emits: ["success"],
  created() {},

  methods: {
    /**
     *
     */
    onAddOpen() {
      this.drawerVisible = true;
    },

    /**
     * 公司选择
     * @param id
     */
    chooseCompanyChange(id) {
      this.form.consumerId = "";
      this.form.companyId = id;
      if (id) {
        this.onGetConsumer();
      }
    },

    /**
     * 项目选择
     * @param {*} ids
     */
    onEstateChange(ids) {
      this.form.estateIds = ids;
      // this.selectEstate = item;
    },

    /**
     *
     * @param q
     */
    onGetConsumer(q) {
      this.loading = true;
      this.$http
        .get(
          `admin/v1/consumer/get_select?q=${q ?? ""}&companyId=${
            this.form.companyId
          }`
        )
        .then((res) => {
          if (res.code == 0) {
            this.consumerList = res.data;
            console.log(this.consumerList);
          }
        })
        .finally(() => (this.loading = false));
    },

    /**
     *
     */
    onSubmit() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("admin/v1/reportnew/add", this.form)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success("添加成功");
                this.$emit("success");
                this.drawerVisible = false;
              }
            })
            .finally(() => (this.saving = false));
        }
      });
    },

    /**
     * 粘贴识别
     */
    onDiscern() {
      this.dialogVisible = true;
    },
    onDiscernSubmit() {
      this.$refs.disFormRef.validate((valid) => {
        if (valid) {
          this.$http
            .post("admin/v1/reportnew/discern", this.disForm)
            .then((res) => {
              if (res.code == 0 && res.data) {
                this.$message.success("识别完成");
                console.log(res.data);
                var item = res.data;

                if (item.company) {
                  this.form.companyId = item.company.id;
                  this.form.companyName = item.company.name;
                  this.selection_company = {
                    id: item.company.id,
                    name: item.company.name,
                  };
                }

                if (res.data.client) {
                  this.form.clientMobile = item.client.mobile;
                  this.form.clientName = item.client.name;
                  this.form.sex = item.client.sex;
                }
                if (item.daikan) {
                  this.form.daikanMobile = item.daikan.mobile;
                  this.form.daikanName = item.daikan.name;
                }
                if (item.dueTime) {
                  this.form.dueTime = item.dueTime;
                }
                if (item.estate) {
                  this.form.estateIds = [item.estate.id];
                  this.selectEstate = {
                    id: item.estate.id,
                    name: item.estate.name,
                    stopStatus: item.estate.stopStatus,
                    stopStatusText: item.estate.stopStatusText,
                    tuijianDesc: item.estate.tuijianDesc,
                  };
                }
                this.form.comment = item.remark;
                this.dialogVisible = false;
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
